'use client';

import React from 'react';
import {
  getSeoOnlyContent,
  createMediaMapFromContent,
  getMediaPropsFromContentfulMedia,
} from 'src/utils/contentful';
import { ContentfulStandardPageLayout } from 'src/components/layout/ContentfulStandardPageLayout';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { PageContent, Section } from 'src/components/layout';
import { FAQ } from 'src/components/subsections/FAQ';
import { FeatureBlock, FeatureBlockCopy, FeatureBlockMedia } from 'src/components/subsections/FeatureBlock';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { FinalCTA } from 'src/components/subsections/FinalCTA';
import { Media } from 'src/tapestry/core/media';
import { FluidText } from 'src/components/common/FluidText';
import { KeyStats } from 'src/components/subsections/KeyStats';
import { FeatureBlockBody } from 'src/components/common/FeatureBlockBody';
import { FeatureBlockDetailsList } from 'src/components/common/FeatureBlockDetailsList';
import { MazeUxrScript } from 'src/components/common/MazeUxrScript';
import { colorSlate20, colorSandLight, colorBlushBase } from 'src/styles/exports.module.scss';
import { OrganizationSchema } from './OrganizationSchema';
import { HomeHeroEvergreen } from './HomeHeroEvergreen';
import { HomeTiers } from './HomeTiers';
import { ContentfulHomeHero } from './ContentfulHomeHero/ContentfulHomeHero.component';
import { translations } from './home.translations';
import {
  productFeatureCell_,
  statsSectionSpacing_,
  supportHeading_,
  productFeatureSectionHeading_,
  finalCtaSection_,
} from './home.module.scss';

export const HomePageBody = ({ page, productFeatures }) => {
  const { appendTranslationKeys, isFr } = useTranslationContext();
  const { faqs } = getSeoOnlyContent(page);
  const mediaMap = createMediaMapFromContent(page.content.items);

  appendTranslationKeys(translations);

  let STATS_ITEM_HEADING_CONFIG;

  if (isFr) {
    STATS_ITEM_HEADING_CONFIG = {
      type: 'h3',
      min: 'ws-display-md',
      max: 'ws-display-lg',
    };
  } else {
    STATS_ITEM_HEADING_CONFIG = {
      type: 'h3',
      min: 'ws-display-xl',
      max: 'ws-display-2xl',
    };
  }

  const [heroTakeover] = page.promotionalContent.items ?? [];

  return (
    <ContentfulStandardPageLayout page={page} withChatbot>
      <OrganizationSchema />
      <MazeUxrScript />
      <PageContent>
        {heroTakeover && <ContentfulHomeHero {...heroTakeover} />}
        <HomeHeroEvergreen mediaMap={mediaMap} />
        <Section background={colorSandLight} verticalSpacingCollapse="bottom">
          <FeatureBlock
            reverseWaterfallAnimation={false}
            presetOverride="twoCellReverseDesktopAlt"
            reverseOrderDesktop
          >
            <FeatureBlockCopy>
              <FeatureBlockBody
                heading={
                  <FluidText type="h2" min="ws-display-md" max="ws-display-2xl">
                    {useTranslation('home::trust::heading')}
                  </FluidText>
                }
                subheading={
                  <FluidText type="p" min="ws-text-lg" max="ws-text-2xl">
                    {useTranslation('home::trust::subheading')}
                  </FluidText>
                }
              />
            </FeatureBlockCopy>
            <FeatureBlockMedia>
              <Media {...getMediaPropsFromContentfulMedia(mediaMap.wsMedia_trustWomanLake)} />
            </FeatureBlockMedia>
          </FeatureBlock>
        </Section>
        <Section
          background={colorSandLight}
          verticalSpacingCollapse="top"
          className={statsSectionSpacing_}
        >
          <KeyStats
            itemHeadingFluidTextConfig={STATS_ITEM_HEADING_CONFIG}
            list={useTranslation('home::stats::list')}
          />
        </Section>
        <Section verticalSpacingCollapse="bottom">
          <SectionHeading
            textAlign="left"
            withSeparator
            heading={
              <FluidText
                type="h2"
                max="ws-display-2xl-serif"
                className={productFeatureSectionHeading_}
              >
                {useTranslation('home::product-feature::2::heading')}
              </FluidText>
            }
          />
          {productFeatures.cash}
        </Section>
        <Section>
          <SectionHeading
            textAlign="left"
            withSeparator
            heading={
              <FluidText
                type="h2"
                max="ws-display-2xl-serif"
                className={productFeatureSectionHeading_}
              >
                {useTranslation('home::product-feature::1::heading')}
              </FluidText>
            }
          />
          {React.cloneElement(productFeatures.managed, { className: productFeatureCell_ })}
          {productFeatures.diy}
        </Section>
        <Section verticalSpacingCollapse="top">
          <FeatureBlock reverseWaterfallAnimation={false} presetOverride="twoCellReverseDesktopAlt">
            <FeatureBlockCopy>
              <FluidText
                className={supportHeading_}
                type="h2"
                min="ws-display-md"
                max="ws-display-2xl"
              >
                {useTranslation('home::support::heading')}
              </FluidText>
              <FeatureBlockDetailsList list={useTranslation('home::support::list')} />
            </FeatureBlockCopy>
            <FeatureBlockMedia>
              <Media {...getMediaPropsFromContentfulMedia(mediaMap.wsMedia_pmSupportZoe)} />
            </FeatureBlockMedia>
          </FeatureBlock>
        </Section>
        <Section background={colorSlate20}>
          <HomeTiers
            premiereMedia={
              <Media {...getMediaPropsFromContentfulMedia(mediaMap.wsMedia_homeTiersPremiereEvo)} />
            }
            generationMedia={
              <Media
                {...getMediaPropsFromContentfulMedia(mediaMap.wsMedia_homeTiersGenerationEvo)}
              />
            }
          />
        </Section>
        <Section
          forceFullWidth
          verticalSpacingCollapse="bottom"
          background={colorBlushBase}
          animation="squeeze-content-do-not-use-or-you-will-be-haunted-by-spooky-ghosts"
          className={finalCtaSection_}
        >
          <FinalCTA
            heading={useTranslation('home::final-cta::heading')}
            subheading={useTranslation('home::final-cta::subheading')}
            media={
              <Media
                {...getMediaPropsFromContentfulMedia(mediaMap.wsMedia_homeEvoFinalCtaMuted)}
                aspectRatio="6.75 / 6"
                controls={false}
              />
            }
          />
        </Section>
        {faqs && (
          <Section>
            <FAQ {...faqs} />
          </Section>
        )}
      </PageContent>
    </ContentfulStandardPageLayout>
  );
};
