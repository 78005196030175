import React, { useEffect, useRef, useState } from 'react';
import { AppDownloadCTA } from 'src/components/common/AppDownloadCTA';
import { RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG, asSubsection } from 'src/components/layout';
import { FluidText } from 'src/components/common/FluidText';
import { VideoControls } from 'src/components/common/VideoControls';
import { classNames } from 'src/utils/css';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import {
  copyContainer,
  finalSubheading,
  finalCta,
  mediaCell,
  mediaContainer,
  videoControls,
  grid_,
} from './FinalCTA.module.scss';

const FinalCTA = asSubsection('FinalCTA')(({ heading, subheading, media, cta }) => {
  const { isPowerSaveMode } = useUserAttributesContext();
  const videoElContainer = useRef();
  const [videoEl, setVideoEl] = useState();

  useEffect(() => {
    const childVideoElement = videoElContainer.current?.getElementsByTagName('video')[0];
    if (childVideoElement) setVideoEl(childVideoElement);
  }, []);

  return (
    <>
      <RefreshGrid xs={{ gap: 0 }} lg={{ gap: GRID_DEFAULT_CONFIG.gap }} className={grid_}>
        <RefreshCell
          className={copyContainer}
          xs={{ width: 10, left: 2 }}
          lg={{ width: 8, left: 3 }}
        >
          <FluidText type="h2" min="ws-display-lg-serif" max="ws-display-3xl-serif">
            {heading}
          </FluidText>
          <FluidText
            type="p"
            min="ws-text-lg"
            max="ws-text-2xl"
            className={finalSubheading}
            isBalanced
          >
            {subheading}
          </FluidText>
          {cta || <AppDownloadCTA className={classNames(finalCta)} />}
        </RefreshCell>
      </RefreshGrid>
      <div className={mediaCell}>
        <div ref={videoElContainer} className={mediaContainer}>
          {media}
        </div>
      </div>
      {videoEl && !isPowerSaveMode && (
        <VideoControls className={videoControls} videoElement={videoEl} />
      )}
    </>
  );
});

export { FinalCTA };
